import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { expandBoundingBox } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { parse } from '../../util/urlHelpers';
import AdvancedSearchForm from './AdvancedSearchForm';

import css from './AdvancedSearch.module.css';

const format = {
  month: 'short',
  day: 'numeric',
};

const AdvancedSearch = props => {
  const { intl, smallSearchView } = props;
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const location = useLocation();

  const currentSearchParams = useMemo(() => parse(location.search), [location.search]);

  const initialSearchFormValues = useMemo(() => {
    const { address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const dates = currentSearchParams.dates ? currentSearchParams.dates.split(',') : null;

    return {
      location: {
        search: address,
        selectedPlace: { address, origin, bounds },
      },
      dates: dates
        ? { startDate: parseDateFromISO8601(dates[0]), endDate: parseDateFromISO8601(dates[1]) }
        : null,
    };
  }, [currentSearchParams]);

  const getValues = values => {
    const { location, dates } = values;
    const selectedPlace = location?.selectedPlace || {};
    const boundsWithRadius = selectedPlace.bounds
      ? expandBoundingBox(
        selectedPlace.address === currentSearchParams.address ? currentSearchParams.placeBounds : selectedPlace.bounds,
        currentSearchParams.pub_radius || 20
      ) : null;

    const searchParams = {
      bounds: boundsWithRadius,
      address: selectedPlace.address || null,
      origin: selectedPlace.origin || null,
      placeBounds: selectedPlace.bounds || null,
      pub_radius: +currentSearchParams.pub_radius || 20,
      dates: dates?.startDate && dates?.endDate
        ? `${stringifyDateToISO8601(dates.startDate)},${stringifyDateToISO8601(dates.endDate)}`
        : null,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) return null;

  return (
    <div className={classNames(css.root, { [css.rootTopbar]: smallSearchView })}>
      <AdvancedSearchForm
        onSubmit={() => {}}
        getValues={getValues}
        intl={intl}
        initialValues={initialSearchFormValues}
        format={format}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
