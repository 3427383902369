import React from 'react';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { updateUserSubscription } from '../../ducks/user.duck';
import { createResourceLocatorString } from '../../util/routes';
import StripePricingTable from './components/StripePricingTable';

import css from './SubscriptionPlans.module.css';

export const SubscriptionPlansComponent = props => {
  const { currentUser, onUpdateUserSubscription } = props;

  const email = currentUser?.attributes?.email;

  const history = useHistory();
  const routes = useRouteConfiguration();

  const onUpdateSubscription = () => {
    onUpdateUserSubscription();

    history.push(createResourceLocatorString('ManageSubscriptionPage', routes, {}, {}));
  }

  return (
    <div className={css.pricingTableStripe}>
      <div className={css.pricingTableItem}>
        <StripePricingTable userEmail={email} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  }
};

const mapDispatchToProps = dispatch => ({
  onUpdateUserSubscription: () => dispatch(updateUserSubscription()),
});

const SubscriptionPlans = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SubscriptionPlansComponent);

export default SubscriptionPlans;
