import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconInfo } from '../../components';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const { className, rootClassName, tooltipText } = props;
  const classes = classNames(rootClassName || css.tooltip, className);

  return (
    <span className={classes}>
    <IconInfo />
    <span className={css.tooltipText}>
      {tooltipText}
    </span>
  </span>
  );
};

const { string } = PropTypes;

Tooltip.defaultProps = {
  className: null,
  rootClassName: null,
};

Tooltip.propTypes = {
  className: string,
  rootClassName: string,
};

export default Tooltip;
