import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  Button,
  IconSearch,
  FieldDateRangeController,
  FieldLocationAutocompleteInput,
  OutsideClickHandler
} from '../../components';
import PopupOpenerButton from '../../containers/SearchPage/PopupOpenerButton/PopupOpenerButton';


import css from './AdvancedSearch.module.css';
import { initialValues } from '../../containers/SearchPage/SearchPage.shared';

const identity = v => v;

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        form,
        format,

      } = formRenderProps;

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingLocationForm.showListingFailed" />
        </p>
      ) : null;

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const currentLocationSelected = !!values?.location?.selectedPlace?.origin;
      const datesFilterSelected = !!values?.dates;

      const [isDatesOpen, setIsDatesOpen] = useState(false);
      const popupControllerRef = useRef(null);

      const handleClear = () => {
        form.change('dates', null)
      }
      const handleCancel = () => {
        setIsDatesOpen(false)
      }
      const handleApply = () => {

        form.change('dates', values?.dates)
        setIsDatesOpen(false)
      }

      const formattedStartDate = !isDatesOpen && values?.dates?.startDate ? intl.formatDate(values.dates.startDate, format) : null;
      const formattedEndDate = !isDatesOpen && values?.dates?.endDate ? intl.formatDate(values.dates.endDate, format) : null;

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus={false}
            name="location"
            label={intl.formatMessage({ id: 'AdvancedSearch.addressLabel' })}
            placeholder={intl.formatMessage({
              id: 'AdvancedSearch.addressPlaceholder',
            })}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            // validate={composeValidators(
            //   autocompletePlaceSelected(addressNotRecognizedMessage)
            // )}
          />

          <OutsideClickHandler
            className={css.datesFilter}
            onOutsideClick={() => setIsDatesOpen(false)}
          >
            <div className={classNames(css.dates, { [css.datesActive]: datesFilterSelected })}>

              <div className={css.dateLabels} onClick={() => setIsDatesOpen(true)}>
                <div className={css.dateLabelsTop}>

                  <label htmlFor="datesIn">
                    <FormattedMessage id="AdvancedSearch.checkIn" />
                  </label>
                  <label htmlFor="datesOut">
                    <FormattedMessage id="AdvancedSearch.checkOut" />
                  </label>
                </div>
                <div className={css.dateLabelsBottom}>
                  <label htmlFor="addDatesIn">
                    {formattedStartDate || <FormattedMessage id="AdvancedSearch.addDates" />}
                  </label>
                  <label htmlFor="addDatesOut">
                    {formattedEndDate || <FormattedMessage id="AdvancedSearch.addDates" />}
                  </label>
                </div>
              </div>

              <div className={classNames(css.datesHolder, { [css.datesHolderOpen]: isDatesOpen })}>
                <FieldDateRangeController
                  name="dates"
                  controllerRef={node => {
                    popupControllerRef.current = node;
                  }}
                />

                <div className={css.buttonsWrapper}>
                  <button className={css.clearButton} type="button" onClick={handleClear}>
                    <FormattedMessage id="AdvancedSearch.clear" />
                  </button>
                  <button className={css.cancelButton} type="button" onClick={handleCancel}>
                    <FormattedMessage id="AdvancedSearch.cancel" />
                  </button>
                  <button className={css.submitButton} type="button" onClick={handleApply}>
                    <FormattedMessage id="AdvancedSearch.apply" />
                  </button>
                </div>
              </div>
            </div>

          </OutsideClickHandler>

          <Button
            className={css.submitButtonMain}
            type="button"
            inProgress={submitInProgress}
            ready={submitReady}
            onClick={() => getValues(values)}
          >
            <IconSearch />
          </Button>
        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
