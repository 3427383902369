import React from 'react';
import classNames from 'classnames';
import videoFile from './video-bg.mp4';

import css from './VideoBackground.module.css';

const VideoBackground = props => {
  const { className, rootClassName, children } = props;
  const classes = classNames(rootClassName, className, css.root);

  return (
    <div className={classes}>
      <video autoPlay loop muted playsInline className={css.videoContent}>
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={css.overlayContent}>
        {children}
      </div>
    </div>
  );
};

export default VideoBackground;
