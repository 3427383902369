import React, { useEffect } from "react";
import { pricingTableId, publishableKey } from '../../../config/configStripe';

const StripePricingTable = ({ userEmail }) => {
  useEffect(() => {
    let script = null;

    if (window) {
      script = document.createElement("script");
      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (window && script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": pricingTableId,
    "publishable-key": publishableKey,
    "customer-email": userEmail,
  });
};

export default StripePricingTable;
