import React from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import * as validators from '../../util/validators';

import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './NewsletterForm.module.css';

const NewsletterFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
      } = formRenderProps;

      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'NewsletterForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'NewsletterForm.emailInvalid',
        })
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <div className={classes}>
          <FormattedMessage id="NewsletterForm.linkTitle" />
          <br />
          <a href="mailto:help@careconnect.market">help@careconnect.market</a>
        </div>
      )

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <h3 className={css.formTitle}>
            <FormattedMessage id="NewsletterForm.title" />
          </h3>
          <p className={css.formDescription}>
            <FormattedMessage id="NewsletterForm.description" />
          </p>

          <FieldTextInput
            className={css.formField}
            type="email"
            id={formId ? `${formId}.email` : 'email'}
            name="email"
            autoComplete="email"
            placeholder={intl.formatMessage({
              id: 'NewsletterForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />

          <div className={css.formButton}>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="NewsletterForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

NewsletterFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
};

NewsletterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const NewsletterForm = compose(injectIntl)(NewsletterFormComponent);
NewsletterForm.displayName = 'NewsletterForm';

export default NewsletterForm;
